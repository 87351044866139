import { useEffect } from 'react';

export const useMousePosition = cb => {
  useEffect(() => {
    const onMouseMove = ({ clientX: mouseX, clientY: mouseY }) => cb(mouseX, mouseY);
    document.addEventListener('mousemove', onMouseMove);

    return () => document.removeEventListener('mousemove', onMouseMove);
  }, [cb]);
};

export const useMouseEnterExitWindow = cb => {
  useEffect(() => {
    const onEnter = () => cb('enter');
    const onExit = () => cb('exit');

    document.addEventListener('mouseenter', onEnter);
    document.addEventListener('mouseleave', onExit);

    return () => {
      document.removeEventListener('mouseenter', onEnter);
      document.removeEventListener('mouseleave', onExit);
    };
  }, [cb]);
};
