import { graphql, useStaticQuery } from 'gatsby';

export const SITE_METADATA = graphql`
  query SiteMetada {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(SITE_METADATA);
  return site.siteMetadata;
};
