import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ children }) => <header>{children}</header>;

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default Header;
