import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { withPrefix } from 'gatsby';

import { colors } from 'src/modules/styles';
import { useSiteMetadata } from 'src/modules/seo';

import favicon from 'src/images/icons/favicon.ico';
import favicon16 from 'src/images/icons/favicon-16x16.png';
import favicon32 from 'src/images/icons/favicon-32x32.png';
import appleTouchIcon from 'src/images/icons/apple-touch-icon.png';

function SEO({ description, lang, meta, title }) {
  const siteMetadata = useSiteMetadata();

  const metaDescription = description || siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      defaultTitle={siteMetadata.title}
    >
      <link rel="shortcut icon" type="image/ico" href={favicon} />
      <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
      <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
      <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
      <meta name="msapplication-TileColor" content={colors.white} />
      <meta name="theme-color" content={colors.white} />
      <meta name="description" content={metaDescription} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={metaDescription} />
      <meta name="og:type" content="website" />
      <meta name="og:url" content="/" />
      <meta name="og:image" content={`${withPrefix('/')}img/og-image.jpg`} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={siteMetadata.author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `el`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default SEO;
