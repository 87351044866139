import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { font, breakpoints, padding } from 'src/modules/styles';

const S = {
  Nav: styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${padding.horizontal.s}
    width: 100%;
    ${font.family.secondary}

    ${breakpoints.m`
      ${padding.horizontal.xl}
    `}
  `,
};

const Nav = ({ children }) => {
  return <S.Nav>{children}</S.Nav>;
};

Nav.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default Nav;
