import { css } from 'styled-components';

import { mapSizes } from './styles.helpers';

const family = {
  primary: css`
    font-family: 'AkzidenzGroteskBEGreek RegEx', sans-serif;
  `,
  secondary: css`
    font-family: 'Akzidenz-Grotesk Pro Cnd', sans-serif;
  `,
};

const size = mapSizes(
  value =>
    css`
      font-size: ${value};
    `,
  {
    xxs: '1em',
    xs: '1.5em',
    s: '2em',
    m: '2.5em',
    l: '3em',
    xl: '3.5em',
  },
);

const weight = {
  lighter: css`
    font-weight: lighter;
  `,
  light: css`
    font-weight: light;
  `,
  normal: css`
    font-weight: normal;
  `,
  bold: css`
    font-weight: bold;
  `,
  bolder: css`
    font-weight: bolder;
  `,
};

export const font = {
  family,
  size,
  weight,
};
