import { css } from 'styled-components';

export const mapSizes = (createStyleFromSize, { xxs, xs, s, m, l, xl, xxl, xxxl }) => ({
  xxs: createStyleFromSize(xxs),
  xs: createStyleFromSize(xs),
  s: createStyleFromSize(s),
  m: createStyleFromSize(m),
  l: createStyleFromSize(l),
  xl: createStyleFromSize(xl),
  xxl: createStyleFromSize(xxl),
  xxxl: createStyleFromSize(xxxl),
});

export const createMediaQuery = ({ minWidth }) => (...args) => css`
  @media (min-width: ${minWidth}) {
    ${css(...args)}
  }
`;
