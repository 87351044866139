import { createContext } from 'react';

export const FocusedElementContext = createContext({
  element: null,
  setFocusedElement: () => {},
});

export const HoverStateContext = createContext({
  isInHoverState: false,
  setIsInHoverState: () => {},
});

export const FullscreenStateContext = createContext({
  isInFullscreenState: false,
  setIsInFullscreenState: () => {},
});
