import React, { useState, useRef, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

import { interpolate } from 'src/modules/animations';
import { useMousePosition, FocusedElementContext, HoverStateContext } from 'src/modules/mouse';
import { colors } from 'src/modules/styles';
import { useDeviceDetector } from 'src/modules/device';
import { useMouseEnterExitWindow } from 'src/modules/mouse/mouse.hooks';

const LARGE_CIRCLE_SIZE = 60;
const BORDER_SIZE = 1;

const S = {
  Cursor: styled.div`
    z-index: 999;
    pointer-events: none;
    position: absolute;
    mix-blend-mode: difference;
  `,
  Circle: styled.div`
    z-index: 999;
    position: absolute;
    background-color: ${colors.white};
    border-radius: 50%;
    height: 1em;
    width: 1em;
    margin-top: -0.5em;
    margin-left: -0.5em;
    mix-blend-mode: difference;
  `,
  LargeCircle: styled.div`
    z-index: 999;
    position: absolute;
    background-color: ${colors.white};
    border: ${BORDER_SIZE}px solid ${colors.white};
    border-radius: 50%;
    mix-blend-mode: difference;
  `,
};

const A = {
  Cursor: animated(S.Cursor),
  Circle: animated(S.Circle),
  LargeCircle: animated(S.LargeCircle),
};

const getSize = ({ isCurosrVisible, isInHoverState, element }) => {
  if (!isCurosrVisible) return { width: 0, height: 0 };
  if (isInHoverState) return { width: LARGE_CIRCLE_SIZE / 2, height: LARGE_CIRCLE_SIZE / 2 };
  if (element && element.size) return element.size;
  return { width: LARGE_CIRCLE_SIZE, height: LARGE_CIRCLE_SIZE };
};

const MouseCursor = () => {
  const [isCurosrVisible, setIsCursorVisible] = useState(false);
  const { isMobile } = useDeviceDetector();
  const { isInHoverState } = useContext(HoverStateContext);
  const { element } = useContext(FocusedElementContext);
  const cursorRef = useRef(null);
  const circleSizeSpring = useSpring({
    size: isCurosrVisible ? 1 : 0,
    opacity: isCurosrVisible ? 1 : 0,
  });
  const [cursorSpring, setCursorSpring] = useSpring(() => ({ xy: [0, 0] }));

  const fillLargeCircle = !isInHoverState && !element;
  const largeCircleSize = getSize({ isCurosrVisible, isInHoverState, element });
  const largeCircleProps = useSpring({
    rgba: [0, 0, 0, fillLargeCircle ? 1 : 0],
    width: largeCircleSize.width,
    height: largeCircleSize.height,
    opacity: isCurosrVisible ? 1 : 0,
    marginTop: -(largeCircleSize.height / 2 + BORDER_SIZE),
    marginLeft: -(largeCircleSize.width / 2 + BORDER_SIZE),
  });

  useEffect(() => {
    if (isInHoverState) return;
    if (!element) return;
    setCursorSpring({ xy: [element.x, element.y] });
  }, [isInHoverState, element, setCursorSpring]);

  useMouseEnterExitWindow(state => {
    switch (state) {
      case 'enter': {
        setIsCursorVisible(true);
        break;
      }
      case 'exit': {
        setIsCursorVisible(false);
        break;
      }
      default:
        break;
    }
  });

  useMousePosition((x, y) => {
    if (!isCurosrVisible) {
      setIsCursorVisible(true);
    }

    if (cursorRef.current) {
      cursorRef.current.style.top = `${y}px`;
      cursorRef.current.style.left = `${x}px`;
    }

    if (element && !isInHoverState) return;
    setCursorSpring({ xy: [x, y] });
  });

  const cursorStyles = ({ xy, ...rest }) => ({
    ...rest,
    transform: interpolate.toTranslate3D(xy),
  });

  const largeCircleStyles = ({ opacity, marginTop, marginLeft, width, height, rgba, ...rest }) => ({
    ...rest,
    opacity: interpolate.id(opacity),
    marginTop: interpolate.toPX(marginTop),
    marginLeft: interpolate.toPX(marginLeft),
    width: interpolate.toPX(width),
    height: interpolate.toPX(height),
    backgroundColor: interpolate.toRGBA(rgba),
  });

  const circleStyles = ({ opacity, size, ...rest }) => ({
    ...rest,
    opacity: interpolate.id(opacity),
    width: interpolate.toEM(size),
    height: interpolate.toEM(size),
  });

  if (isMobile) return null;

  return (
    <>
      <A.Cursor style={cursorStyles(cursorSpring)}>
        <A.LargeCircle style={largeCircleStyles(largeCircleProps)} />
      </A.Cursor>
      <S.Cursor ref={cursorRef}>
        <A.Circle style={circleStyles(circleSizeSpring)} />
      </S.Cursor>
    </>
  );
};

export default MouseCursor;
