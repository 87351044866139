import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import Div100vh from 'react-div-100vh';
import { BrowserView, MobileView } from 'react-device-detect';
import { Link } from 'gatsby';
import { useSpring, animated } from 'react-spring';

import { font, padding, breakpoints } from 'src/modules/styles';
import { Nav, NavItem, NavLink } from 'src/modules/navigation';
import { MouseCursor, FocusedElementContext, HoverStateContext, FullscreenStateContext } from 'src/modules/mouse';
import { SEO } from 'src/modules/seo';
import Logo2SVG from 'src/images/svgs/logo_2.svg';
import EnElSVG from 'src/images/svgs/el_en.svg';
import { useDeviceDetector } from 'src/modules/device';
import Container from './layout.Container';
import Header from './layout.Header';
import Footer from './layout.Footer';

const S = {
  Wrapper: styled(Div100vh)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    background-color: white;
  `,
  NavWrapper: styled(Container)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    ${breakpoints.s`
      ${padding.vertical.s}
    `}
  `,
  Test: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // height: 100%;
  `,
  Sider: styled.div`
    padding-left: 10px;
    padding-right: 10px;
  `,
  Logo: styled(Logo2SVG)`
    width: 60px;
  `,
  EnEl: styled(EnElSVG)`
    width: 60px;
  `,
  RouteContainerMobile: styled.div`
    overflow: hidden;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    .tl-edges {
      overflow-y: hidden;
      overflow-x: hidden;
      height: 100%;
      width: 100%;

      .tl-wrapper {
        position: inherit;
        overflow-y: hidden;
        overflow-x: hidden;
        height: 100%;
      }
    }
  `,
  RouteContainer: styled.div`
    overflow: hidden;
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    .tl-edges {
      overflow-y: hidden;
      overflow-x: hidden;
      height: 100%;
      width: 100%;

      .tl-wrapper {
        position: inherit;
        overflow-y: hidden;
        overflow-x: hidden;
        height: 100%;
        ${padding.horizontal.s}

        ${breakpoints.xs`
          padding-left: 0;
          padding-right: 0;
        `}
      }
    }
  `,
  EspaLink: styled(Link)`
    z-index: 1;
    width: 100%;
    height: 100px;
  `,
  EspaBannerWrapper: styled.div`
    z-index: 1;
    width: 100%;
    height: 100px;
  `,
  EspaBanner: styled.img`
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: contain;
  `,
};

const GlobalStyle = createGlobalStyle`
  * {
    cursor: none;
    -webkit-overflow-scrolling: touch;
  }

  body {
    overflow: hidden;
    margin: 0;
    ${font.family.primary}
    font-size: 16px;

    ${breakpoints.xs`
      font-size: 18px;
    `}

    ${breakpoints.xl`
      font-size: 20px;
    `}

    ${breakpoints.xxl`
      font-size: 22px;
    `}
  }

  *::-webkit-scrollbar {
    display:none;
  }
  
  *::-webkit-scrollbar-track {
    display:none;
  }
  
  *::-webkit-scrollbar-thumb {
    display:none;
  }
  
  .dg.ac {
    z-index: 9999 !important;
  }

  svg text {display: none;}
  svg g:hover text {display: block;}
`;

const A = {
  EspaBannerWrapper: animated(S.EspaBannerWrapper),
};

const checkRoute = (pathname, route, lang) => {
  if (!route) return lang ? pathname === `/${lang}/` : pathname === `/`;
  return lang ? pathname.startsWith(`/${lang}/${route}`) : pathname.startsWith(`/${route}`);
};

const getLang = pathname => {
  const [, lang] = pathname.split('/');
  if (['el', 'en'].includes(lang)) {
    return lang;
  }
  return '';
};

const getPathNameWithoutLang = pathname => {
  const [, lang, ...rest] = pathname.split('/');
  if (['el', 'en'].includes(lang)) {
    return rest.join('/');
  }
  return pathname;
};

const Layout = ({ children, location }) => {
  const [focusedElement, setFocusedElement] = useState(null);
  const [isInHoverState, setIsInHoverState] = useState(false);
  const [isInFullscreenState, setIsInFullscreenState] = useState(false);

  const { pathname } = location;
  const lang = getLang(pathname);
  const { isMobile } = useDeviceDetector();
  const espaBannerAnimatedStyles = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: checkRoute(pathname, '', lang) ? 1 : 0,
    },
    delay: 400,
  });

  if (isMobile)
    return (
      <HoverStateContext.Provider value={{ isInHoverState, setIsInHoverState }}>
        <FocusedElementContext.Provider value={{ element: focusedElement, setFocusedElement }}>
          <FullscreenStateContext.Provider value={{ isInFullscreenState, setIsInFullscreenState }}>
            <S.Wrapper>
              <SEO />
              <GlobalStyle />
              <MouseCursor />
              <Header>
                <S.NavWrapper>
                  <Nav>
                    <NavItem disabled={isInFullscreenState}>
                      <NavLink lang={lang} to="/about" current={checkRoute(pathname, 'about', lang)}>
                        {lang === 'en' ? '1' : '1'}
                      </NavLink>
                    </NavItem>
                    <MobileView>
                      <S.Sider>
                        <NavItem
                          horizontal
                          disabled={checkRoute(pathname, '', lang) || isInFullscreenState}
                          hide={checkRoute(pathname, '', lang) || isInFullscreenState}
                        >
                          <NavLink lang={lang} to="/">
                            <S.Logo />
                          </NavLink>
                        </NavItem>
                      </S.Sider>
                    </MobileView>
                    <NavItem disabled={isInFullscreenState}>
                      <NavLink lang={lang} delay={100} to="/services" current={checkRoute(pathname, 'services', lang)}>
                        {lang === 'en' ? '2' : '2'}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </S.NavWrapper>
              </Header>
              <S.RouteContainerMobile>{children}</S.RouteContainerMobile>
              <Footer>
                {checkRoute(pathname, '', lang) && !isInFullscreenState && (
                  <A.EspaBannerWrapper style={espaBannerAnimatedStyles}>
                    <S.EspaLink to="/espa">
                      <S.EspaBanner src="https://res.cloudinary.com/sermaidis-cloudinary/image/upload/v1632065757/espa_banner_qs5lad.jpg" />
                    </S.EspaLink>
                  </A.EspaBannerWrapper>
                )}
                <S.NavWrapper>
                  <Nav>
                    <NavItem disabled={isInFullscreenState}>
                      <NavLink lang={lang} delay={200} to="/others" current={checkRoute(pathname, 'others', lang)}>
                        {lang === 'en' ? '3' : '3'}
                      </NavLink>
                    </NavItem>
                    {/* <MobileView> */}
                    {/*   <S.Sider> */}
                    {/*     <NavItem horizontal> */}
                    {/*       <NavLink lang={lang === 'en' ? '' : 'en'} to={getPathNameWithoutLang(pathname)}> */}
                    {/*         <S.EnEl /> */}
                    {/*       </NavLink> */}
                    {/*     </NavItem> */}
                    {/*   </S.Sider> */}
                    {/* </MobileView> */}
                    <NavItem disabled={isInFullscreenState}>
                      <NavLink lang={lang} delay={300} to="/contact" current={checkRoute(pathname, 'contact', lang)}>
                        {lang === 'en' ? '4' : '4'}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </S.NavWrapper>
              </Footer>
            </S.Wrapper>
          </FullscreenStateContext.Provider>
        </FocusedElementContext.Provider>
      </HoverStateContext.Provider>
    );

  return (
    <HoverStateContext.Provider value={{ isInHoverState, setIsInHoverState }}>
      <FocusedElementContext.Provider value={{ element: focusedElement, setFocusedElement }}>
        <FullscreenStateContext.Provider value={{ isInFullscreenState, setIsInFullscreenState }}>
          <S.Wrapper>
            <SEO />
            <GlobalStyle />
            <MouseCursor />
            <Header>
              <S.NavWrapper>
                <Nav>
                  <NavItem disabled={isInFullscreenState}>
                    <NavLink lang={lang} to="/about" current={checkRoute(pathname, 'about', lang)}>
                      {lang === 'en' ? '1' : '1'}
                    </NavLink>
                  </NavItem>
                  <NavItem disabled={isInFullscreenState}>
                    <NavLink lang={lang} delay={100} to="/services" current={checkRoute(pathname, 'services', lang)}>
                      {lang === 'en' ? '2' : '2'}
                    </NavLink>
                  </NavItem>
                </Nav>
              </S.NavWrapper>
            </Header>
            <S.RouteContainer>
              <BrowserView>
                <S.Sider>
                  <NavItem
                    horizontal
                    disabled={checkRoute(pathname, '', lang)}
                    hide={checkRoute(pathname, '', lang) || isInFullscreenState}
                  >
                    <NavLink lang={lang} to="/">
                      <S.Logo />
                    </NavLink>
                  </NavItem>
                </S.Sider>
              </BrowserView>
              {children}
              <BrowserView>
                <S.Sider>
                  <NavItem horizontal disabled>
                    {/* <NavLink lang={lang === 'en' ? '' : 'en'} to={getPathNameWithoutLang(pathname)}> */}
                    {/*   <S.EnEl /> */}
                    {/* </NavLink> */}
                  </NavItem>
                </S.Sider>
              </BrowserView>
            </S.RouteContainer>
            <Footer>
              <S.NavWrapper>
                <Nav>
                  <NavItem disabled={isInFullscreenState}>
                    <NavLink lang={lang} delay={200} to="/others" current={checkRoute(pathname, 'others', lang)}>
                      {lang === 'en' ? '3' : '3'}
                    </NavLink>
                  </NavItem>
                  <A.EspaBannerWrapper style={espaBannerAnimatedStyles}>
                    {checkRoute(pathname, '', lang) && !isInFullscreenState && (
                      <S.EspaLink to="/espa">
                        <S.EspaBanner src="https://res.cloudinary.com/sermaidis-cloudinary/image/upload/v1632065757/espa_banner_qs5lad.jpg" />
                      </S.EspaLink>
                    )}
                  </A.EspaBannerWrapper>
                  <NavItem disabled={isInFullscreenState}>
                    <NavLink lang={lang} delay={300} to="/contact" current={checkRoute(pathname, 'contact', lang)}>
                      {lang === 'en' ? '4' : '4'}
                    </NavLink>
                  </NavItem>
                </Nav>
              </S.NavWrapper>
            </Footer>
          </S.Wrapper>
        </FullscreenStateContext.Provider>
      </FocusedElementContext.Provider>
    </HoverStateContext.Provider>
  );
};

Layout.propTypes = {
  location: PropTypes.shape({
    key: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    hash: PropTypes.string,
  }).isRequired,
  children: PropTypes.element.isRequired,
};

export default Layout;
