import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({ children }) => <footer   style={{ zIndex: 1 }}>{children}</footer>;

Footer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default Footer;
