export const distanceFromCenter = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];

export const distancePerc = (x, y) => [(x * 100) / window.innerWidth, (y * 100) / window.innerHeight];

export const distanceFromMouse = (x, y, mouseX, mouseY) =>
  Math.floor(Math.sqrt(Math.pow(mouseX - x, 2) + Math.pow(mouseY - y, 2)));

export const inBounds = (boundingBox, element) => {
  const minX = boundingBox.left;
  const maxX = minX + boundingBox.width - element.width;
  const minY = boundingBox.top;
  const maxY = minY + boundingBox.height - element.height;

  return (x, y) => [
    Math.max(minX + element.width, Math.min(x, maxX)),
    Math.max(minY + element.height, Math.min(y, maxY)),
  ];
};
