import { createMediaQuery } from './styles.helpers';

export const breakpoints = {
  xs: createMediaQuery({ minWidth: '576px' }),
  s: createMediaQuery({ minWidth: '768px' }),
  m: createMediaQuery({ minWidth: '992px' }),
  l: createMediaQuery({ minWidth: '1200px' }),
  xl: createMediaQuery({ minWidth: '1600px' }),
  xxl: createMediaQuery({ minWidth: '2560px' }),
};
