// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-about-page-js": () => import("/opt/build/repo/src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-contact-page-js": () => import("/opt/build/repo/src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-other-page-js": () => import("/opt/build/repo/src/templates/other-page.js" /* webpackChunkName: "component---src-templates-other-page-js" */),
  "component---src-templates-others-page-js": () => import("/opt/build/repo/src/templates/others-page.js" /* webpackChunkName: "component---src-templates-others-page-js" */),
  "component---src-templates-service-page-js": () => import("/opt/build/repo/src/templates/service-page.js" /* webpackChunkName: "component---src-templates-service-page-js" */),
  "component---src-templates-services-page-js": () => import("/opt/build/repo/src/templates/services-page.js" /* webpackChunkName: "component---src-templates-services-page-js" */),
  "component---src-pages-index-el-js": () => import("/opt/build/repo/src/pages/index.el.js" /* webpackChunkName: "component---src-pages-index-el-js" */),
  "component---src-pages-index-en-js": () => import("/opt/build/repo/src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-espa-js": () => import("/opt/build/repo/src/pages/espa.js" /* webpackChunkName: "component---src-pages-espa-js" */)
}

