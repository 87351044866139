import { css } from 'styled-components';

import { mapSizes } from './styles.helpers';

const sizes = {
  xxs: '3px',
  xs: '6px',
  s: '12px',
  m: '24px',
  l: '48px',
  xl: '96px',
  xxl: '192px',
};

export const padding = {
  ...mapSizes(
    value =>
      css`
        padding: ${value};
      `,
    sizes,
  ),
  top: mapSizes(
    value =>
      css`
        padding-top: ${value};
      `,
    sizes,
  ),
  right: mapSizes(
    value =>
      css`
        padding-right: ${value};
      `,
    sizes,
  ),
  bottom: mapSizes(
    value =>
      css`
        padding-bottom: ${value};
      `,
    sizes,
  ),
  left: mapSizes(
    value =>
      css`
        padding-left: ${value};
      `,
    sizes,
  ),
  horizontal: mapSizes(
    value => css`
      padding-right: ${value};
      padding-left: ${value};
    `,
    sizes,
  ),
  vertical: mapSizes(
    value => css`
      padding-top: ${value};
      padding-bottom: ${value};
    `,
    sizes,
  ),
};

