import _ from 'lodash';

const id = x => x;

const toPX = (data, calc = id) => data.interpolate(value => `${calc(value)}px`);

const toEM = (data, calc = id) => data.interpolate(value => `${calc(value)}em`);

const toPerc = (data, calc = id) => data.interpolate(value => `${calc(value)}%`);

const toRGBA = data => data.interpolate((r, g, b, a) => `rgba(${r}, ${g}, ${b}, ${a})`);

const toTranslate3D = (data, type = 'px') =>
  data.interpolate((x = 0, y = 0, z = 0) => `translate3d(${x}${type}, ${y}${type}, ${z}${type})`);

const toTranslateX = (data, type = 'px') => data.interpolate(x => `translate3d(${x}${type}, 0${type}, 0${type})`);

const toTranslateY = (data, type = 'px') => data.interpolate(y => `translate3d(0${type}, ${y}${type}, 0${type})`);

const minMax = (min, max) => value => Math.min(Math.max(value, min), max);

export const interpolate = {
  id,
  toPX,
  toEM,
  toPerc,
  toRGBA,
  toTranslate3D,
  toTranslateX,
  toTranslateY,
  minMax,
  size: ({ height, width, ...rest }) => ({
    ...rest,
    height: height.interpolate(value => `${value}px`),
    width: width.interpolate(value => `${value}px`),
  }),
  height: ({ height, ...rest }) => ({
    ...rest,
    height: height.interpolate(value => `${value}px`),
  }),
  width: ({ width, ...rest }) => ({
    ...rest,
    width: width.interpolate(value => `${value}px`),
  }),
  margin: ({ margin, ...rest }) => ({
    ...rest,
    margin: margin.interpolate((top, right, bottom, left) => `${top}px ${right}px ${bottom}px ${left}px`),
  }),
  backgroundRGBA: ({ rgba, ...rest }) => ({
    ...rest,
    background: rgba.interpolate((r, g, b, a) => `rgba(${r}, ${g}, ${b}, ${a})`),
  }),
  top: ({ y, ...rest }) => ({
    ...rest,
    top: y.interpolate(value => value),
  }),
  left: ({ x, ...rest }) => ({
    ...rest,
    left: x.interpolate(value => value),
  }),
  translateXY: ({ xy, transform, ...rest }) => ({
    ...rest,
    transform: xy
      ? xy.interpolate((valueX, valueY) => _.compact([transform, `translate3d(${valueX}px, ${valueY}px, 0)`]).join(' '))
      : transform,
  }),
  translateX: ({ x, ...rest }) => ({ ...rest, transform: x.interpolate(value => `translate3d(${value}px, 0, 0)`) }),
  translateY: ({ y, ...rest }) => ({ ...rest, transform: y.interpolate(value => `translate3d(0, ${value}px, 0)`) }),
  skewX: ({ x, ...rest }) => ({
    ...rest,
    transform: x.interpolate(value => `skewX(${value}deg)`),
  }),
};
