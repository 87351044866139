import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { useMousePosition, FocusedElementContext, distanceFromMouse } from 'src/modules/mouse';

const S = {
  Wrapper: styled.div`
    transition: opacity 500ms;
    opacity: ${({ hide }) => (hide ? 0 : 1)};
    z-index: 1;
    overflow: hidden;
    width: 90px;
    ${({ blend }) =>
      blend &&
      css`
        mix-blend-mode: difference;
      `}
  `,
};

const NavItem = ({ children, horizontal, disabled, hide, blend }) => {
  const { setFocusedElement } = useContext(FocusedElementContext);
  const elementRef = useRef(null);
  const [hasFocus, setHasFocus] = useState(false);

  useEffect(() => {
    if (!disabled) return;
    if (hasFocus) {
      setFocusedElement(null);
      setHasFocus(false);
    }
  }, [disabled]);

  useMousePosition((x, y) => {
    if (disabled) return;
    if (!elementRef.current) return;

    const element = elementRef.current.getBoundingClientRect();
    const elementX = element.left + element.width / 2;
    const elementY = element.top + element.height / 2;
    const distance = distanceFromMouse(elementX, elementY, x, y);
    const isInRange = distance < 150;

    if (isInRange && !hasFocus) {
      setFocusedElement({
        x: elementX,
        y: elementY,
        size: {
          height: horizontal ? 100 : 80,
          width: horizontal ? 80 : 100,
        },
      });
      setHasFocus(true);
    } else if (!isInRange && hasFocus) {
      setFocusedElement(null);
      setHasFocus(false);
    }
  });

  return (
    <S.Wrapper ref={elementRef} blend={blend} hide={hide}>
      {children}
    </S.Wrapper>
  );
};

NavItem.propTypes = {
  horizontal: PropTypes.bool,
  disabled: PropTypes.bool,
  hide: PropTypes.bool,
  blend: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

NavItem.defaultProps = {
  disabled: false,
  hide: false,
  blend: false,
  horizontal: false,
};

export default NavItem;
