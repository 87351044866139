import { css } from 'styled-components';

import { mapSizes } from './styles.helpers';

const sizes = {
  xxs: '3px',
  xs: '6px',
  s: '12px',
  m: '24px',
  l: '48px',
  xl: '96px',
  xxl: '192px',
};

export const margin = {
  ...mapSizes(
    value =>
      css`
        margin: ${value};
      `,
    sizes,
  ),
  top: mapSizes(
    value =>
      css`
        margin-top: ${value};
      `,
    sizes,
  ),
  right: mapSizes(
    value =>
      css`
        margin-right: ${value};
      `,
    sizes,
  ),
  bottom: mapSizes(
    value =>
      css`
        margin-bottom: ${value};
      `,
    sizes,
  ),
  left: mapSizes(
    value =>
      css`
        margin-left: ${value};
      `,
    sizes,
  ),
  horizontal: mapSizes(
    value => css`
      margin-right: ${value};
      margin-left: ${value};
    `,
    sizes,
  ),
  vertical: mapSizes(
    value => css`
      margin-top: ${value};
      margin-bottom: ${value};
    `,
    sizes,
  ),
};
