import React from 'react';
import PropTypes from 'prop-types';
// import Link from 'gatsby-plugin-transition-link';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

import { colors, font } from 'src/modules/styles';
import BackArrow from 'src/images/svgs/back_arrow.svg';

const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: row;
  `,
  LinkWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
  `,
  Link: styled(Link)`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    ${font.size.xl}
    color: ${colors.black};
    text-align: center;
    text-decoration: none;
    // -webkit-text-stroke: 1px ${colors.white};
  `,
  ArrowWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  BackArrow: styled(BackArrow)`
    padding-left: 15px;
    padding-right: 15px;
    height: 60px;
    width: 60px;
  `,
};

const A = {
  Wrapper: animated(S.Wrapper),
};

const NavLink = ({ current, lang, to, delay, blend, children }) => {
  const animatedStyles = useSpring({
    from: {
      transform: 'translate3d(180px, 0, 0)',
    },
    transform: current ? 'translate3d(-90px,0,0)' : 'translate3d(0, 0, 0)',
    delay,
  });

  const onArrowClick = () => {
    window.history.back();
  };

  return (
    <A.Wrapper style={animatedStyles}>
      <S.LinkWrapper>
        <S.Link
          to={lang === 'el' ? to : `/${lang}${to}`}
          current={current}
          exit={{
            length: 0.3,
            delay: 0,
          }}
          entry={{
            length: 0.3,
            delay: 0,
          }}
        >
          {children}
        </S.Link>
      </S.LinkWrapper>
      <S.ArrowWrapper onClick={onArrowClick}>
        <S.BackArrow />
      </S.ArrowWrapper>
    </A.Wrapper>
  );
};

NavLink.propTypes = {
  current: PropTypes.bool,
  lang: PropTypes.string,
  to: PropTypes.string.isRequired,
  delay: PropTypes.number,
  blend: PropTypes.bool,
  children: PropTypes.string.isRequired,
};

NavLink.defaultProps = {
  delay: 0,
  current: false,
  blend: false,
  lang: null,
};

export default NavLink;
