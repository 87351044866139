import { useEffect, useState } from 'react';
// import UAParser from 'ua-parser-js';
const UAParser = typeof window !== `undefined` ? require('ua-parser-js') : null;

export const useDeviceDetector = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (!!window && !!window.navigator && window.navigator.userAgent && UAParser) {
      const ua = new UAParser(window.navigator.userAgent);
      console.log(ua);
      const device = ua.getDevice();
      setIsMobile(['mobile', 'tablet'].includes(device.type));
    }
  }, []);

  return { isMobile };
};
