import { useMemo } from 'react';
import _ from 'lodash';

const filterStatusAnimations = status => animation => animation.status.includes(status);

const getStyles = _.property('styles');

export const useTransitionAnimation = ({ config, from }, animations, status) => {
  const memoizedAnimation = useMemo(() => {
    const x = animations.filter(filterStatusAnimations(status)).map(getStyles);
    const result = Object.assign({ config, from }, ...x);
    return result;
  }, [config, from, animations, status]);

  return memoizedAnimation;
};
