import styled, { css } from 'styled-components';
import { breakpoints } from 'src/modules/styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${({ notCenter }) =>
    !notCenter
      ? css`
          margin: 0 auto;
        `
      : null}
  width: auto;

  ${breakpoints.xs`
    width: 540px;
  `}

  ${breakpoints.s`
    width: 540px;
  `}

  ${breakpoints.m`
    width: 960px;
  `}

  ${breakpoints.l`
    width: 1140px;
  `}

  ${breakpoints.xl`
    width: 1500px;
  `}

  ${breakpoints.xxl`
    width: 2400px;
  `}
`;

export default Container;
